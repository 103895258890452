import { graphql } from 'gatsby'
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useBreakpoint,
} from '@chakra-ui/react'
import { SectionContainer } from '@components/SectionContainer'
import { Hero } from '@components/Hero'
import litUp from '../assets/images/litup.png'
import { ExpandableList } from '@components/ExpandableList'
import { Link } from '@components/Link'

const LitUpPage = ({ data }: { data: any }) => {
  const { hero, roadmap, readership, guidelines, partners, inauguralClass } =
    data.litUpJson

  inauguralClass.people.forEach((person: {
    imageUrl: any, imageName: any
  }) => {
    data.allFile.edges.forEach((file: {
      node: {
        publicURL: any, name: any
      }
    }) => {
      if (file.node.name === person.imageName) {
        person.imageUrl = file.node.publicURL
      }
    })
  })

  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'

  return (
    <>
      <SectionContainer bgColor="rgba(0, 0, 0, 0) linear-gradient(rgba(167, 169, 246, 0.99) 0%, rgb(218, 241, 232) 100%)">
        <Hero
          smallTitle={hero.subtitle}
          largeTitle={hero.title}
          img={hero.image.childImageSharp}
          ctaCopy={hero.text}
          ctaText={hero.cta}
          ctaUrl={hero.url}
          ctaAnalyticsEvent="apply_now_link_clicked"
          infoText={hero.info}
          ctaCenter={true}
        />
      </SectionContainer>
      <SectionContainer bgColor="#F4F2EA">
        <Box mb={24} maxW="container.md" mx="auto" mt={24}>
          <Text
            textAlign="center"
            textTransform="uppercase"
            fontWeight={500}
            lineHeight="1em"
            fontSize={16}
            mb={4}
          >
            {roadmap.subtitle}
          </Text>
          <Heading
            fontWeight={500}
            textAlign="center"
            fontSize={40}
            lineHeight="1em"
            variant="sans-serif"
            mb={2}
          >
            {roadmap.title}
          </Heading>
          <Text textAlign="center">{roadmap.text}</Text>
        </Box>
        <Box
          w="full"
          fontSize={40}
          maxW="container.md"
          mx="auto"
          mb={12}
          textAlign="center"
        >
          <Flex direction={'row'} justifyContent={'center'} alignItems={'center'}>
            <Text>The</Text>
            <Image src={litUp} display="inline-block" h="1em" position="relative"
              bottom={'-2px'} ml={3} mr={3}
            />
            <Text>Roadmap:</Text>
          </Flex>
        </Box>
        <Flex
          direction={isMobile ? 'column' : 'row'}
          alignItems="stretch"
          maxW="6xl"
          mx="auto"
          mb={24}
        >
          {roadmap.items.map((content: any) => (
            <Flex
              textAlign="center"
              direction="column"
              mx={isMobile ? 0 : 4}
              mb={isMobile ? 12 : 0}
              key={content.title}
              flex="1"
            >
              <Box>
                <Image
                  h={48}
                  srcSet={content.image.childImageSharp?.fluid?.srcSet}
                  alt={content.title}
                  m={0}
                  mx="auto"
                />
              </Box>

              <Box h="16" mt="8">
                <Heading variant="sans-serif" fontSize={24} m={0}>
                  {content.title}
                </Heading>
              </Box>

              <Text mt="8">{content.text}</Text>
            </Flex>
          ))}
        </Flex>
      </SectionContainer>
      <SectionContainer bgColor="rgb(0, 105, 80)">
        <Flex
          direction="column"
          alignItems="center"
          maxW="container.md"
          mx="auto"
          py={20}
        >
          <Image src={readership.icon.publicURL} w={32} mb={12} />
          <Heading
            color="white"
            textAlign="center"
            mb={8}
            fontSize={40}
            whiteSpace="pre-wrap"
          >
            {readership.title}
          </Heading>
          <Text color="white" textAlign="center" mb={8} fontSize={18}>
            {readership.text}
          </Text>
          <Button
            as={Link}
            to={readership.url}
            linkEvent="litup_readership_link_clicked"
          >
            {readership.cta}
          </Button>
        </Flex>
      </SectionContainer>

      <Container pt={{ base: '24' }} pb={{ base: '12' }} maxWidth="900px">
        <Stack spacing={{ base: '20' }}>
          <Stack spacing="3" align="center" textAlign="center">
            <Stack spacing={{ base: '4', md: '5' }}>
              <Heading
                fontWeight={500}
                textAlign="center"
                fontSize={40}
                lineHeight="1em"
                variant="sans-serif"
                mb={2}
              >
                {inauguralClass.title}
              </Heading>
              <Text textAlign="center" mb={8} fontSize={18}>
                {inauguralClass.subtitle}
              </Text>
            </Stack>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3, lg: 3 }}
            columnGap="24"
            rowGap={{ base: '12', lg: '12' }}
          >
            {inauguralClass.people.map(
              (
                member: {
                  name: string
                  cycle: string
                  url: string
                  title: string
                  genre: string
                  imageName: string
                  imageUrl: string
                },
                index: number
              ) => (
                <Stack
                  key={member.name}
                  spacing="3"
                  align="center"
                  textAlign="center"
                >
                  <Stack spacing={{ base: '4', md: '5' }} align="center">
                    <Avatar
                      src={`${member.imageUrl}`}
                      width="195px"
                      height="195px"
                    />
                    <Box>
                      <Text fontWeight="medium" fontSize="lg">
                        {member.name}
                      </Text>
                      <Text>{member.cycle}</Text>
                      <Text>'{member.title}'</Text>
                      <Text>{member.genre}</Text>
                    </Box>
                  </Stack>
                </Stack>
              )
            )}
          </SimpleGrid>
        </Stack>
      </Container>

      <SectionContainer>
        <Box py={16}>
          <Heading
            fontWeight={500}
            textAlign="center"
            fontSize={40}
            lineHeight="1em"
            variant="sans-serif"
            mb={12}
          >
            {guidelines.title}
          </Heading>
          <Flex
            flexDirection="column"
            alignItems="center"
            maxW="container.md"
            mx="auto"
          >
            <ExpandableList data={guidelines.questions} />
            <Button
              as={Link}
              to={guidelines.url}
              linkEvent="more_faq_link_clicked"
              variant="blue"
              size="stout"
              mt={12}
              mb={4}
            >
              {guidelines.cta}
            </Button>
            <Link
              to={guidelines.termsUrl}
              fontSize={14}
              lineHeight="1em"
              color="rgb(227, 76, 113)"
            >
              {guidelines.termsCta}
            </Link>
          </Flex>
        </Box>
      </SectionContainer>
      <SectionContainer bgColor="#F4F2EA">
        <Flex py={24} direction="column">
          <Heading
            fontSize={40}
            textAlign="center"
            textTransform="uppercase"
            mb={12}
          >
            {partners.title}
          </Heading>
          <Text
            fontSize={18}
            textAlign="center"
            mb={12}
            maxW="container.sm"
            mx="auto"
          >
            {partners.text}
          </Text>
          <Flex
            flexWrap="wrap"
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            {partners.items.map((p: any) => (
              <Flex
                direction="column"
                w="sm"
                mb={20}
                justifyContent="flex-start"
                alignItems="center"
                key={p.text}
              >
                <Image
                  srcSet={p.image.childImageSharp.fluid.srcSet}
                  h={16}
                  mb={8}
                />
                <Text textAlign="center">{p.text}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </SectionContainer>
    </>
  )
}

export default LitUpPage

export const query = graphql`
  query LitUpQuery {
    allFile(filter: {extension: {eq: "png"}}) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    litUpJson {
      hero {
        title
        subtitle
        text
        info
        cta
        url
        image {
          childImageSharp {
            fluid(maxWidth: 550, quality: 100) {
              srcSet
              aspectRatio
            }
          }
        }
      }
      roadmap {
        title
        subtitle
        text
        heading {
          childMarkdownRemark {
            html
          }
        }
        items {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 200, quality: 100) {
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
      readership {
        icon {
          publicURL
        }
        title
        text
        cta
        url
        images {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 655, quality: 100) {
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
      guidelines {
        title
        cta
        url
        termsCta
        termsUrl
        questions {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      partners {
        title
        text
        items {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 150, quality: 100) {
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
      inauguralClass {
        title
        subtitle
        people {
          name
          title
          genre
          cycle
          imageName
        }
      }
      newVoices {
        title
        cta
        url
        image {
          childImageSharp {
            fluid(maxWidth: 148, maxHeight: 139, quality: 100) {
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  }
`
