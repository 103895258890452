import { graphql } from 'gatsby'
import { SectionContainer } from '@components/SectionContainer'
import { format } from 'date-fns'
import { Hero } from '@components/Hero'
import {
  AspectRatio,
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react'
import slugify from 'slugify'
import { Tag } from '@components/Tag'
import { Link } from '@components/Link'
import { AppleStore, Close, GooglePlayStore } from '@components/Icon'
import { ContentfulBook } from 'types/graphql-types'
import { useEffect } from 'react'

type Book = ContentfulBook & { titleNode: { title: string } }

interface Edge {
  node: Book
}

interface PicksPageProps {
  data: {
    allContentfulBook: {
      edges: Edge[]
    }
  }
}

const PicksPage = ({ data }: PicksPageProps) => {
  const picks = data.allContentfulBook.edges
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'

  const heroPicks = picks.slice(0, 2)
  const listPicks = picks.slice(2)

  return (
    <>
      <SectionContainer bgColor="rgba(0, 0, 0, 0) linear-gradient(178.88deg, rgb(255, 153, 102) 0.96%, rgba(252, 213, 229, 0.9) 64.29%, rgb(238, 236, 224) 122.56%) repeat scroll 0% 0%">
        <Hero
          largeTitle="Women: May we write them truer, Raise them higher and read them here"
          smallTitle="Discover The Latest Pick"
          ctaCopy=""
          picks={heroPicks}
        />
      </SectionContainer>
      <SectionContainer>
        <Box mt={24}>
          <Heading textAlign="center" fontSize={48} mb={12}>
            The Choices (and Adventures) Abound
          </Heading>
          <Grid
            gridTemplateColumns={isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
            gap={12}
            maxW="container.lg"
            mx="auto"
          >
            {listPicks?.map(p => (
              <Pick p={p.node} key={p.node.id} showTag />
            ))}
          </Grid>
        </Box>
      </SectionContainer>
    </>
  )
}

export const Pick = ({ p, linkStyle }: { p: Book; linkStyle?: any }) => {
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'
  const { isOpen, onOpen, onClose } = useDisclosure()

  const tagString = `${p.season || ''} ${format(
    new Date(p.pickDate),
    p.season ? '\'\'yy' : 'MMM \'\'yy'
  )} ${p.pickCollection === 'Main' ? 'PICK' : 'YA PICK'}`

  const slug = slugify(p.titleNode?.title || '', {
    lower: true,
    trim: true,
    strict: true,
  })

  useEffect(() => {
    if (slug === window.location.hash.replace('#', '')) {
      onOpen()
    } else {
      onClose()
    }
  }, [slug, onClose, onOpen])

  const close = () => {
    onClose()
    window.history.replaceState(null, '', '/picks')
  }

  return (
    <Box
      as={Link}
      onClick={onOpen}
      linkEvent="book_details_open"
      to={`/picks/#${slug}`}
      options={{ replace: true }}
      style={linkStyle}
    >
      <AspectRatio ratio={0.65777} mb={4}>
        <Image srcSet={p.coverImage?.fluid?.srcSet} objectFit="cover" />
      </AspectRatio>
      <Tag
        size="full"
        variant={p.pickCollection === 'Main' ? 'yellow' : 'blue'}
      >
        {tagString}
      </Tag>
      <Modal
        isOpen={isOpen}
        onClose={close}
        isCentered
        size={isMobile ? 'full' : '4xl'}
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent boxShadow="none">
          <Box
            w={4}
            position="absolute"
            right={4}
            top={4}
            as="button"
            onClick={close}
          >
            <Close />
          </Box>
          <ModalBody py={isMobile ? 8 : 24} px={isMobile ? 4 : 20}>
            <Flex direction={isMobile ? 'column' : 'row'}>
              <AspectRatio
                w="3xs"
                ratio={p.coverImage?.fluid?.aspectRatio}
                flexShrink={0}
                mx="auto"
                mb={isMobile ? 12 : 0}
              >
                <Image srcSet={p.coverImage?.fluid?.srcSet} />
              </AspectRatio>
              <Box px={8} flexGrow={1}>
                <Tag variant={p.pickCollection === 'Main' ? 'yellow' : 'blue'}>
                  {tagString}
                </Tag>
                <Heading
                  variant="sans-serif"
                  fontSize={36}
                  lineHeight="1em"
                  my={4}
                >
                  {p.titleNode?.title}
                </Heading>
                <Text fontSize={20} lineHeight="1em">
                  {p.author}
                </Text>
                <Box mt={8}>
                  {'Available on '}
                  {p.purchaseLinks?.map((l, i) => {
                    const isLast = i === (p.purchaseLinks?.length || 0) - 1

                    return (
                      <span key={l?.name}>
                        {isLast ? 'and ' : ''}
                        <Link color="yellow.500" to={l?.url?.url || ''}>
                          {l?.name}
                        </Link>
                        {!isLast ? ', ' : ''}
                      </span>
                    )
                  })}
                </Box>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default PicksPage

export const query = graphql`
  query PicksQuery {
    allContentfulBook(sort: { fields: [pickDate], order: DESC }) {
      edges {
        node {
          id
          titleNode: title {
            title
          }
          author
          purchaseLinks {
            name
            url {
              url
            }
          }
          coverImage {
            title
            description
            fluid(maxWidth: 162, quality: 100) {
              aspectRatio
              srcSet
            }
          }
          purchaseLinks {
            url {
              url
            }
            os
          }
          pickCollection
          pickDate
          season
        }
      }
    }
  }
`
