import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  useBreakpoint,
  useBreakpointValue,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { Link } from '@components/Link'
import type { ReactNode } from 'react'
import type { ContentfulAsset } from 'types/graphql-types'
import { Pick } from '@pages/picks'

export type HeroVariants = 'compact' | 'vertical' | undefined

interface HeroProps {
  smallTitle?: string
  largeTitle: string
  img?: ContentfulAsset | (ContentfulAsset | null | undefined)[]
  ctaText?: string
  ctaUrl?: string
  ctaCopy: ReactNode
  ctaAnalyticsEvent?: string
  infoText?: ReactNode
  gradient?: 'purple' | 'yellow'
  size?: 'md' | 'lg'
  textColor?: string
  bgColor?: string
  roundImage?: boolean
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  picks?: any
  ctaCenter?: boolean
}

export const Hero = ({
  smallTitle,
  largeTitle,
  img,
  ctaCopy,
  ctaUrl,
  ctaText,
  ctaAnalyticsEvent,
  infoText,
  gradient,
  size = 'md',
  textColor,
  bgColor,
  roundImage,
  picks,
  ctaCenter
}: HeroProps) => {
  const bp = useBreakpoint()

  const variant = useBreakpointValue({
    base: 'vertical',
    md: 'compact',
    lg: 'normal',
  })
  const heroSize = useBreakpointValue({
    base: 'md',
    md: size === 'md' ? 'md' : 'compact',
    lg: size === 'md' ? 'md' : 'lg',
  })

  const gapSize = useBreakpointValue({
    base: 8,
    md: 8,
    lg: 12,
  })

  const isMobile = bp === 'base' || bp === 'sm'

  const styles = useMultiStyleConfig('Hero', { heroSize, variant })

  const purpleGradient =
    'linear-gradient(180deg, #A7A9F6 25.52%, rgba(252, 213, 229, 0.54) 100%)'
  const yellowGradient = 'linear-gradient(180deg, #FFF34B 0%, #F6D7E2 100%);'
  return (
    <Box
      bgGradient={
        gradient === 'purple' ? purpleGradient : gradient && yellowGradient
      }
      background={bgColor}
      size={size}
      __css={styles.container}
    >
      <Flex __css={styles.contentContainer}>
        <Flex color={textColor || 'inherit'} __css={styles.textContainer}>
          {smallTitle && (
            <Heading as="h4" sx={styles.smallTitle}>
              {smallTitle}
            </Heading>
          )}
          <Heading as="h1" sx={styles.largeTitle}>
            {largeTitle}
          </Heading>
          <Box maxW={size} __css={styles.ctaCopy}>
            {ctaCopy}
          </Box>
          {ctaText && ctaUrl && (
            <Box maxW={size}>
              {ctaCenter ? (
                <Center>
                  <Button as={Link} to={ctaUrl} mt={8} linkEvent={ctaAnalyticsEvent}>
                    {ctaText}
                  </Button>
                </Center>
              ) : (
                <Button as={Link} to={ctaUrl} mt={8} linkEvent={ctaAnalyticsEvent}>
                  {ctaText}
                </Button>
              )}
            </Box>
          )}
          {infoText && <Box mt={4}>{infoText}</Box>}
        </Flex>
        {Array.isArray(img) ? (
          <SimpleGrid
            gridTemplateColumns={`repeat(${img.length}, 1fr)`}
            gap={gapSize}
          >
            {img.map((image, index) => {
              return (
                <AspectRatio
                  ratio={img.length === 1 ? 1 : 0.65777}
                  mb={4}
                  sx={styles.image}
                  key={`hero-image-${index}`}
                  maxW={img.length === 1 ? 'full' : 48}
                >
                  <Flex position="relative" direction="column">
                    <Image srcSet={image?.fluid?.srcSet} />
                    {image?.description?.length ? (
                      <Box
                        mt="-44px"
                        mr="8px"
                        alignSelf="flex-end"
                        zIndex="10"
                        py={2}
                        px={4}
                        background="white"
                        textColor="#002FA4"
                        borderRadius="sm"
                        fontSize="xs"
                        textTransform="uppercase"
                      >
                        {image.description}
                      </Box>
                    ) : (
                      ''
                    )}
                  </Flex>
                </AspectRatio>
              )
            })}
          </SimpleGrid>
        ) : img?.fluid ? (
          <AspectRatio
            ratio={1}
            borderRadius={roundImage ? '100%' : 'none'}
            overflow="hidden"
            sx={Object.assign({}, styles.image, {
              '& > img': {
                objectFit: 'scale-down',
                maxHeight: '100%',
              },
            })}
          >
            <Flex position="relative" direction="column">
              <Image
                srcSet={img?.fluid?.srcSet}
                maxH="100%"
                objectFit="scale-down"
              />
              {img?.description?.length ? (
                <Box
                  mt="-44px"
                  mr="8px"
                  alignSelf="flex-end"
                  zIndex="10"
                  py={2}
                  px={4}
                  background="white"
                  textColor="#002FA4"
                  borderRadius="sm"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  {img.description}
                </Box>
              ) : (
                ''
              )}
            </Flex>
          </AspectRatio>
        ) : null}
        <SimpleGrid
          gridTemplateColumns={`repeat(${picks?.length}, 1fr)`}
          gap={gapSize}
        >
          {picks?.map((p: any) => (
            <AspectRatio
              ratio={picks.length === 1 ? 1 : 0.65777}
              mb={4}
              sx={styles.image}
              key={p.node.id}
              maxW={picks.length === 1 ? 'full' : 48}
            >
              <Flex position="relative" direction="column">
                <Pick
                  p={p.node}
                  linkStyle={
                    !isMobile ? { width: '100%', height: '100%' } : null
                  }
                />
              </Flex>
            </AspectRatio>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  )
}
