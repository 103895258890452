import { useState } from 'react'
import { Box } from '@chakra-ui/react'

const ListItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  let html = answer

  if (typeof answer === 'object') {
    html = answer.childMarkdownRemark.html
  }

  return (
    <Box
      aria-expanded={isOpen}
      aria-hidden={!isOpen}
      onClick={toggle}
      key={question}
    >
      <Box
        fontSize="xl"
        cursor="pointer"
        py={6}
        borderBottom="1px solid black"
        position="relative"
        pr={12}
      >
        <Box mb={isOpen ? 4 : 0}>{question}</Box>
        <Box
          fontSize="lg"
          h={isOpen ? 'auto' : 0}
          py={isOpen ? 8 : 0}
          overflow="hidden"
          m={0}
          sx={{
            '& p': {
              mb: 4,
            },
            '& ul': {
              pl: 12,
              mb: 4,
            },
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        ></Box>
        <Box
          position="absolute"
          right={0}
          top={10}
          w="11px"
          h="11px"
          lineHeight={0}
          fontSize={0}
          border="medium none"
          _before={{
            content: '\'\'',
            display: 'block',
            position: 'absolute',
            background: 'black',
            w: '11px',
            h: '1px',
            marginLeft: '-5px',
          }}
          _after={{
            content: '\'\'',
            display: 'block',
            position: 'absolute',
            background: 'black',
            mt: '-5px',
            w: '1px',
            height: '11px',
            transition: 'all 0.25s ease-in-out 0s',
            opacity: 1,
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
          }}
        ></Box>
      </Box>
    </Box>
  )
}

export const ExpandableList = ({ data }) => {
  return (
    <Box>
      {data.map(({ question, answer }) => (
        <ListItem question={question} answer={answer} key={question} />
      ))}
    </Box>
  )
}
